import _ from 'lodash';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export const TeamsList = ({ teams }) => {
  
  const [search, setSearch] = useState('');
  
  if (teams.length === 0) {
    return <div className="alert alert-warning"><i className="fas fa-spin fa-spinner fa-fw me-2"></i>Loading...</div>
  }

  let filteredTeams = teams;
  
  if(search) filteredTeams = teams.filter(team => {return team.player.nickname.toLowerCase().includes(search.toLowerCase()) || team.tournament.name.toLowerCase().includes(search.toLowerCase())});

  console.log(search, teams, filteredTeams)

  return <div className='card mb-3'>
    <div className='card-header'>Teams</div>
    <div className='card-body p-1'>
      <input type='text' className='form-control mb-1' placeholder='Search...' value={search} onChange={e => setSearch(e.target.value)}/>
      <div className='table-responsive'><table className='table table-dark table-bordered mb-0 table-striped'>
        <thead>
          <tr>
            <th>Player Nickname</th>
            <th>Tournament</th>
            <th className='text-center'>1</th>
            <th className='text-center'>2</th>
            <th className='text-center'>3</th>
            <th className='text-center'>4</th>
            <th className='text-center'>5</th>
            <th className='text-center'>6</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredTeams.map(team => {

            let imgPrefix = "http://localhost:3333"
            if (process.env.NODE_ENV === 'production') {
              imgPrefix = ""
            }

            return <tr key={team.id}>
              <td>{team.player.nickname}</td>
              <td>{team.tournament.name} @ {team.tournament.location}</td>
              {_.times(6, (i) => {
                let pkmnId = team[`pokemon${i + 1}`].id
                let img = `${imgPrefix}/pokemon_sprites/sprites/pokemon/other/home/${pkmnId}.png`
                return <td key={i} className='text-center'><img src={img} style={{width: "60px"}}/></td>
              })}
              <td className='text-end'>
                <Link to={`/teams/${team.id}/edit`} className='btn btn-sm btn-primary'>Edit</Link>
              </td>
            </tr>
          })}
        </tbody>
      </table></div>
    </div>
  </div>;
}