import {
  createBrowserRouter,
  RouterProvider,
  useRouteError
} from "react-router-dom";
import { Menu } from "./Menu/Menu";
import { Tournaments } from "./Tournaments/Tournaments";
import { Players } from "./Players/Players";
import { Teams } from "./Teams/Teams";
import { TeamBuilder } from "./Teams/TeamBuilder";
import { Matches } from "./Matches/Matches";
import { Stream } from "./Stream/Stream";

const CustomError = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="alert alert-danger m-3">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p className="mb-0">
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
}

const PageWrapper = ({...props}) => {
  return <div className="d-flex flex-nowrap" style={{height: "100vh"}}>
    <Menu />
    <div className="container-fluid" style={{ marginLeft: "280px"}}>
      {props.children}
    </div>
  </div>
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <PageWrapper>
      <div className="alert alert-info mt-2">
        Nothing found here please select site you want to use
      </div>
    </PageWrapper>,
    errorElement: <CustomError />
  },{
    path: "/tournaments",
    element: <PageWrapper><Tournaments /></PageWrapper>
  },{
    path: "/players",
    element: <PageWrapper><Players /></PageWrapper>
  }, {
    path: "/players/:playerId/edit",
    element: <PageWrapper><Players /></PageWrapper>
  }, {
    path: "/teams",
    element: <PageWrapper><Teams /></PageWrapper>
  }, {
    path: "/teams/new",
    element: <PageWrapper><TeamBuilder /></PageWrapper>
  }, {
    path: "/teams/:teamId/edit",
    element: <PageWrapper><Teams /></PageWrapper>
  }, {
    path: "/matches",
    element: <PageWrapper><Matches /></PageWrapper>
  }, {
    path: "/matches/:matchId/edit",
    element: <PageWrapper><Matches /></PageWrapper>
  }, {
    path: "/stream",
    element: <PageWrapper><Stream /></PageWrapper>
  }
]);

export const CustomRouter = () => {
  return <RouterProvider router={router} />;
}
