import React, { useEffect, useState } from 'react';
import { UserContext } from '../../hooks/auth';
import axios from 'axios';
import { Link } from 'react-router-dom';

export const ListMatches = () => {
  
  const [matches, setMatches] = useState([]);
  const {token} = React.useContext(UserContext);
  const [refresh, setRefresh] = useState(new Date().toISOString());

  useEffect(() => {
    axios.get("/backend/matches", {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then(response => {
      setMatches(response.data)
    })
  }, [refresh])
  
  const streamMatch = (id) => {
    axios.post(`/backend/matches/${id}/stream`, {}, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then(response => {
      setRefresh(new Date().toISOString())
    })
  }

  return (
    <div className='card'>
      <div className='card-header'>
        Matches
      </div>
      <div className='card-body p-1'>
        <table className='table table-dark table-bordered mb-0 table-striped'>
          <thead>
            <tr>
              <th>Player 1</th>
              <th>Player 2</th>
              <th>Tournament</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {matches.map(match => {
              return <tr key={match.id}>
                <td>{match.playerLeft.nickname}</td>
                <td>{match.playerRight.nickname}</td>
                <td>{match.tournament.name} @ {match.tournament.location}</td>
                <td className='text-end'>
                  <button onClick={() => {
                    streamMatch(match.id)
                  }} className={"btn btn-sm me-2 " + (match.stream ? "btn-success" : "btn-danger")}>{match.stream ? "On Stream" : "Not on Stream"}</button>
                  <Link to={`/matches/${match.id}/edit`} className='btn btn-sm btn-primary'>Edit</Link>
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}