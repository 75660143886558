import { usePlayers } from "../../hooks/players"
import { useTournaments } from "../../hooks/tournaments"
import { useEffect, useState } from "react"
import { UserContext } from "../../hooks/auth"
import React from "react"
import axios from "axios"

export const NewMatch = () => {

  const players = usePlayers()
  const tournaments = useTournaments()
  const {token} = React.useContext(UserContext)
  const [uploading, setUploading] = useState(false)

  const [tournamnet, setTournament] = useState(undefined)
  const [player1, setPlayer1] = useState(undefined)
  const [player2, setPlayer2] = useState(undefined)

  if(!players || !tournaments) {
    return <div className="alert alert-warning"><i className="fas fa-spin fa-spinner fa-fw me-2"></i>Loading...</div>
  }

  const formattedTournaments = tournaments.map(tournament => {
    return {
      id: tournament.id,
      name: `${tournament.name} @ ${tournament.location}`
    }
  })

  const formattedPlayers = players.map(player => {
    return {
      id: player.id,
      name: `${player.nickname} - ${player.firstname} ${player.lastname}`
    }
  })

  const createMatch = () => {
    if(uploading) return;
    setUploading(true)

    axios.post("/backend/matches", {
      player1Id: player1,
      player2Id: player2,
      tournamentId: tournamnet
    }, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then(response => {
      console.log(response.data)
      setPlayer1(undefined)
      setPlayer2(undefined)
      setTournament(undefined)
    }).finally(() => {
      setUploading(false)
    })
  }

  if(uploading) {
    return <div className="alert alert-info"><i className="fas fa-spin fa-spinner fa-fw me-2"></i>Creating Match...</div>
  }

  return <div className="card mt-3">
    <div className="card-header">New Match</div>
    <div className="card-body">
      <div className="row">
        <div className="col-12 col-xxl-4">
          <CustomSelect prefix="tournament" label="Tournament" options={formattedTournaments} value={tournamnet} onChange={(e) => setTournament(e.target.value)} />
          <CustomSelect prefix="player1" label="Player 1" options={formattedPlayers} value={player1} onChange={(e) => setPlayer1(e.target.value)} />
          <CustomSelect prefix="player2" label="Player 2" options={formattedPlayers} value={player2} onChange={(e) => setPlayer2(e.target.value)} last />
          {tournamnet && player1 && player2 && <div className="mt-3"><button className="btn btn-primary w-100" onClick={createMatch}>Create Match</button></div>}
        </div>
        <div className="col-12 col-xxl-8">
          {player1 && tournamnet && <Team tournament={tournamnet} player={player1} label={"Player 1"} />}
          {player2 && tournamnet && <Team tournament={tournamnet} player={player2} label={"Player 2"} extra={"mt-3"} />}
        </div>
      </div>
    </div>
  </div>
}

const Team = ({tournament, player, label, extra}) => {
  
  console.log(player, label)

  const [team, setTeam] = useState(undefined)
  const {token} = React.useContext(UserContext)

  useEffect(() => {
    if(player) {
      axios.get(`/backend/teams/players/${player}/${tournament}`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      }).then(response => {
        setTeam(response.data)
      })
    }
  }, [player])

  if(!player) {
    return <div className="alert alert-info"><b className="me-2">{label}</b>Select a player to see their team</div>
  }

  if(!team) {
    return <div className="alert alert-danger"><b className="me-2">{label}</b>No Team found for this Player</div>
  }

  return <div className={"row " + extra}>
    <div className="col-12">
      <div className="alert alert-success">{label} - Team</div>
    </div>
    <Pokemon pkmn={team.pokemon1} />
    <Pokemon pkmn={team.pokemon2} />
    <Pokemon pkmn={team.pokemon3} />
    <Pokemon pkmn={team.pokemon4} />
    <Pokemon pkmn={team.pokemon5} />
    <Pokemon pkmn={team.pokemon6} />
  </div>
}

const Pokemon = ({pkmn}) => {

  let imgPrefix = "http://localhost:3333"

  if (process.env.NODE_ENV === 'production') {
    imgPrefix = ""
  }

  let image = `${imgPrefix}/pokemon_sprites/sprites/pokemon/other/home/${pkmn?.id}.png`

  return <div className="col-12 col-xxl-4">
    <div className="card mb-3">
      <div className="card-body">
        <img src={image} alt={pkmn?.name} className="img-fluid"/>
      </div>
    </div>
  </div>
}

const CustomSelect = ({ prefix, label, options, value, onChange, last = false }) => {
  return <div className={"form-floating " + (last ? "" : "mb-3")}>
    <select className="form-select" onChange={onChange} value={value}>
      <option value="">Select {label}</option>
      {options.map((option, index) => <option key={prefix + "_" + option.id + "_" + option.name + "_" + index} value={option.id}>{option.name}</option>)}
    </select>
    <label>{label}</label>
  </div>
}