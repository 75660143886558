import { useEffect } from "react"
import "./Ingame.scss"
import axios from "axios"
import _ from "lodash"
import { useState } from "react"
import { PokemonDataContext } from "../../hooks/pokemon"
import { useInterval } from "usehooks-ts"
import { useContext } from "react"
import classNames from "classnames"

export const InGameOverlay = () => {

  useEffect(() => {
    //console.log("Mounting Ingame Overlay")

    let body = document.querySelector("body");
    body.classList.add("ingame-overlay")

    return () => {
      body.classList.remove("ingame-overlay")

      //console.log("Unmounting Ingame Overlay")
    }
  }, [])

  return <PokemonDataWrapper><Overlay /></PokemonDataWrapper>
}

const Overlay = ({ ...props }) => {

  const [match, setMatch] = useState(null)

  useInterval(() => {
    axios.get("/backend/pokemon/stream").then(response => {
      //console.log(response.data)
      setMatch(response.data)
    }).catch(error => {
      console.error(error)
    })
  }, 1000)


  if (match?.match === null || match?.team1 == null || match?.team2 == null) {
    console.log("Match not found")
    return;
  }

  let ingameOverlayShown =  match.match.config.ingame_overlay
  if(ingameOverlayShown === undefined) ingameOverlayShown = false;

  if(!ingameOverlayShown) return;

  return <div className="overlay-wrapper">
    <DataLayer m={match} />
    <BackgroundLayer m={match} />
  </div>
}

const DataLayer = ({ m, ...props }) => {
  return <>
    <Scores m={m} />
    <PokemonMembersLeft m={m} />
    <PokemonMembersRight m={m} />
    <TournamentData m={m} />
  </>
}

const TournamentData = ({ m, ...props }) => {
  return <div className="tournament-data">
    <div className="tournament-name">{m.match.tournament.name}</div>
    <div className="tournament-round">ROUND 1</div>
  </div>
}

const Scores = ({ m, ...props }) => {

  let playerLeft = m.match.playerLeft
  let playerRight = m.match.playerRight

  const countryLeft = playerLeft.country.toLowerCase()
  const countryRight = playerRight.country.toLowerCase()

  let bestOfText = "Best of 1"

  if(m.match.mode == "bo3") {
    bestOfText = "Best of 3"
  }

  if(m.match.mode == "bo5") {
    bestOfText = "Best of 5"
  }

  if(m.match.mode == "swiss") {
    bestOfText = "Swiss"
  }

  return <div className="scores">
    <div className="team-left-scores">
      <div className="inner-bg"></div>
      <div className="team-left-score">{m.match.scoreLeft}</div>
      <div className="team-left-name">{playerLeft.nickname}</div>
      <div class="team-left-swiss-score">
        <span className="w">{m.match.swissWinsLeft}</span>/<span className="l">{m.match.swissLossLeft}</span>
      </div>
      <img src={`https://flagcdn.com/h40/${countryLeft}.png`} className="team-left-flag" alt=""/>
    </div>
    <div className="versus">VS</div>
    <div className="best-of">
      {bestOfText}
    </div>
    <div className="team-right-scores">
    <div className="inner-bg"></div>
      <div className="team-right-score">{m.match.scoreRight}</div>
      <div className="team-right-name">{playerRight.nickname}</div>
      <div class="team-right-swiss-score">
        <span className="w">{m.match.swissWinsRight}</span>/<span className="l">{m.match.swissLossRight}</span>
      </div>
      <img src={`https://flagcdn.com/h40/${countryRight}.png`} className="team-right-flag" alt=""/>
    </div>
  </div>
}

const PokemonMembersLeft = ({ m, ...props }) => {
  return <div className="team-left">
    <PokemonMember m={m} number={1} teamNr={1} team={m.team1} />
    <div className="split left"></div>
    <PokemonMember m={m} number={2} teamNr={1} team={m.team1} />
    <div className="split left"></div>
    <PokemonMember m={m} number={3} teamNr={1} team={m.team1} />
    <div className="split left"></div>
    <PokemonMember m={m} number={4} teamNr={1} team={m.team1} />
  </div>
}

const PokemonMembersRight = ({ m, ...props }) => {
  return <div className="team-right">
    <PokemonMember m={m} number={1} teamNr={2} team={m.team2} />
    <div className="split right"></div>
    <PokemonMember m={m} number={2} teamNr={2} team={m.team2} />
    <div className="split right"></div>
    <PokemonMember m={m} number={3} teamNr={2} team={m.team2} />
    <div className="split right"></div>
    <PokemonMember m={m} number={4} teamNr={2} team={m.team2} />
  </div>
}

const PokemonMember = ({ m, number, team, teamNr }) => {
  const pokemonClass = `pokemon pkmn-${number} team-${teamNr}`
  const { items } = useContext(PokemonDataContext)
  const pokeballImg = teamNr === 1 ? "great-ball" : "poke-ball"

  let config = m.match.config;
  let teamIndex = config["order_" + teamNr + "_" + number]

  let imgPrefix = "http://localhost:3333"
  if (process.env.NODE_ENV === 'production') imgPrefix = "";

  if (teamIndex === undefined) {
    return <div className={pokemonClass}>
      <img src={`${imgPrefix}/pokemon_sprites/sprites/items/dream-world/${pokeballImg}.png`} className="error-ball" alt=""/>
    </div>
  }

  let pokemon = team["pokemon" + teamIndex]
  let item = items.find(a => a.name === pokemon.item)
  let itemName = item.name;
  let hidden = config["hidden_" + teamNr + "_" + teamIndex]
  if (hidden === undefined) hidden = true;
  let fainted = config["fainted_" + teamNr + "_" + teamIndex];
  if (fainted === undefined) fainted = false;
  let active = config["active_" + teamNr + "_" + teamIndex];
  if (active === undefined) active = false;
  let status = config["status_" + teamNr + "_" + teamIndex]
  if(status == undefined) status = "none";

  let teraIndex = config[`tera_${teamNr}`]
  let teraActive = teraIndex == teamIndex;
  let shiny = pokemon.shiny;
  let shinyPrefix = shiny ? "/shiny" : ""
  let tera = pokemon.tera;

  //console.log(hidden, fainted)

  let pokemonImage = `${imgPrefix}/pokemon_sprites/sprites/pokemon/other/official-artwork${shinyPrefix}/${pokemon?.id}.png`
  let itemImage = `${imgPrefix}/pokemon_sprites/sprites/items/dream-world/${itemName}.png`
  let teraImage = `${imgPrefix}/pokemon_sprites/sprites/types/generation-ix/scarlet-violet/TeraKristall/${tera.toLowerCase()}.png`


  if (hidden) {
    return <div className={pokemonClass}>
      <img src={`${imgPrefix}/pokemon_sprites/sprites/items/dream-world/${pokeballImg}.png`} className="error-ball" alt=""/>
    </div>
  }

  let sts = "";
  if(status == "burn") sts = "brn";
  if(status == "frozen") sts = "frz";
  if(status == "paralyze") sts = "par";
  if(status == "poison") sts = "psn";
  if(status == "sleep") sts = "slp";
  if(status == "confused") sts = "con";

  return <div className={classNames(pokemonClass, fainted ? "fainted" : "", (active && !fainted) ? " active" : "")} onClick={() => console.log(pokemon
  )}>
    <img src={pokemonImage} className={"pokemon-img "} alt=""/>
    <div className="item">
      <img src={itemImage} className="item-img" alt=""/>
    </div>
    {teraActive && <div className="tera">
      <img src={teraImage} className="tera-img" alt=""/>
    </div>}
    {status !== "none" && <div className={"status " + status.toString().toLowerCase()}>
      {sts}
    </div>}
  </div>
}

const BackgroundLayer = ({ m, ...props }) => {

  return <>
    <span className="team-left-title">TEAM</span>
    <div className="team-left-pokemons">
      <div className="inner-bg"></div>
    </div>
    <span className="team-right-title">TEAM</span>
    <div className="team-right-pokemons">
      <div className="inner-bg"></div>
    </div>
  </>
}

export const PokemonDataWrapper = ({ ...props }) => {

  //const [refresh, setRefresh] = useState(new Date().toISOString())
  const [pokemons, setPokemons] = useState([])
  const [items, setItems] = useState([])
  const [abilities, setAbilities] = useState([])
  const [moves, setMoves] = useState([])

  const fetchPokemons = () => {
    axios.get("/backend/pokemon/data/pokemons").then(response => {
      setPokemons(_.sortBy(response.data, "name"))
    }).catch(error => {
      console.log(error)
    })
  }

  const fetchItems = () => {
    axios.get("/backend/pokemon/data/items").then(response => {
      setItems(_.sortBy(response.data, "name"))
    }).catch(error => {
      console.log(error)
    })
  }

  const fetchAbilities = () => {
    axios.get("/backend/pokemon/data/abilities").then(response => {
      setAbilities(_.sortBy(response.data, "name"))
    }).catch(error => {
      console.log(error)
    })
  }

  const fetchMoves = () => {
    axios.get("/backend/pokemon/data/moves").then(response => {
      setMoves(_.sortBy(response.data, "name"))
    }).catch(error => {
      console.log(error)
    })
  }

  useEffect(fetchPokemons, [])
  useEffect(fetchItems, [])
  useEffect(fetchAbilities, [])
  useEffect(fetchMoves, [])

  if (pokemons.length === 0 || items.length === 0 || abilities.length === 0 || moves.length === 0) {
    return;
  }

  return <PokemonDataContext.Provider value={{ pokemons: pokemons, items: items, abilities: abilities, moves: moves }}>
    {props.children}
  </PokemonDataContext.Provider>
}
