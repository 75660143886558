import { useState } from 'react';
import { usePlayers } from '../../hooks/players';
import { PlayersList } from './List';
import { NewPlayer } from './New';
import { Outlet } from 'react-router-dom';
import { EditPlayer } from './Edit';
import { useParams } from 'react-router-dom';

export const Players = () => {
  const [refresh, setRefresh] = useState(new Date().toISOString())
  
  let { playerId } = useParams();
  const players = usePlayers(refresh)

  return <div className="row mt-2">
    <div className="col-12 col-xxl-8">
      <PlayersList players={players} refresh={setRefresh} />
    </div>
    <div className="col-12 col-xxl-4">
      <Outlet />
      {!playerId && <NewPlayer callback={setRefresh} />}
      {playerId && <EditPlayer playerId={playerId} callback={setRefresh} />}
    </div>
  </div>
}