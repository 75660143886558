import { Link } from "react-router-dom"
import { TeamsList } from "./List"
import { useTeams } from "../../hooks/teams"
import { useParams } from "react-router-dom"
import { EditTeam } from "./Edit"
import { useState } from "react"

export const Teams = () => {

  const [refresh, setRefresh] = useState(new Date().toISOString())
  const teams = useTeams(refresh)
  const {teamId} = useParams()

  return <div className="row mt-2">
    <div className="col-12 text-end mb-3">
      <Link to="/teams/new" className="btn btn-primary">New Team</Link>
    </div>
    {teamId && <div className="col-12">
      <EditTeam teamId={teamId} callback={setRefresh} />
    </div>}
    <div className="col-12">
      {!teamId && <TeamsList teams={teams} />}
    </div>
  </div>
}