import React, { useEffect, useState } from 'react'
import { UserContext } from '../../hooks/auth'
import axios from 'axios'
import { Pokemon, PokemonData } from './TeamBuilder'
import { Link } from 'react-router-dom'

export const EditTeam = ({teamId, callback}) => {
  
  const [team, setTeam] = useState(undefined)
  const {token} = React.useContext(UserContext)

  useEffect(() => {
    axios.get(`/backend/teams/${teamId}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      console.log(res.data)
      setTeam(res.data)
    }).catch(err => {
      console.log(err)
    })
  }, [teamId, token])

  if(!team) {
    return <div className='alert alert-warning'>
      <i className='fas fa-spinner fa-spin fa-fw me-2'></i> Loading...
    </div>
  }

  return <div className="row mb-3">
    <div className="col-12 col-xxl-3">
      <TeamGeneralData team={team} />  
    </div>
    <div className="col-12 col-xxl-9">
      <TeamData team={team} setTeam={setTeam} callback={callback} />
    </div>
  </div>
}

const TeamData = ({team, setTeam, callback}) => {

  const [updating, setUpdating] = useState(false)
  const [pokemon1, setPokemon1] = useState(team.pokemon1)
  const [pokemon2, setPokemon2] = useState(team.pokemon2)
  const [pokemon3, setPokemon3] = useState(team.pokemon3)
  const [pokemon4, setPokemon4] = useState(team.pokemon4)
  const [pokemon5, setPokemon5] = useState(team.pokemon5)
  const [pokemon6, setPokemon6] = useState(team.pokemon6)
  const {token} = React.useContext(UserContext)

  const updateTeam = () => {
    setUpdating(true)
    if(updating) return

    axios.patch(`/backend/teams/${team.id}`, {
      pokemon1, pokemon2, pokemon3, pokemon4, pokemon5, pokemon6
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      console.log(res.data)
      setTeam(res.data)
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      setUpdating(false)
      callback(new Date().toISOString())
    })
  }

  if(updating) {
    return <div className='alert alert-warning'>
      <i className='fas fa-spinner fa-spin fa-fw me-2'></i> Updating...
    </div>
  }

  return <PokemonData>
    <div className='row'>
    <div className='col-12 mb-3'>
        <button className='btn btn-primary' onClick={updateTeam} disabled={updating}>Update</button>
        <Link to={`/teams`} className='btn btn-secondary ms-2'>Cancel</Link>
      </div>
      <Pokemon pokemon={pokemon1} setPokemon={setPokemon1} />
      <Pokemon pokemon={pokemon2} setPokemon={setPokemon2} />
      <Pokemon pokemon={pokemon3} setPokemon={setPokemon3} />
      <Pokemon pokemon={pokemon4} setPokemon={setPokemon4} />
      <Pokemon pokemon={pokemon5} setPokemon={setPokemon5} />
      <Pokemon pokemon={pokemon6} setPokemon={setPokemon6} />
    </div>
  </PokemonData>
}

const TeamGeneralData = ({team}) => {
  return <div className="card">
    <div className='card-header'>General Data</div>
    <div className='card-body'>
      {team.player.nickname} <img src={`https://flagcdn.com/h20/${team.player.country.toLowerCase()}.png`} />
      <hr />
      {team.tournament.name} @ {team.tournament.location}
    </div>
  </div>
}