import { useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { UserContext } from '../../hooks/auth';
import TextInput from '../../components/TextInput';
import axios from 'axios';

export const NewTournament = ({ callback }) => {
  const methods = useForm()
  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    console.log(data)
    if(loading) return;
    setLoading(true)

    axios.post("/backend/tournaments", data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      setLoading(false)
      methods.reset()
      callback(new Date().toISOString())
    }).catch(err => {
      console.log(err)
      setLoading(false)
    })
  }

  return <div className='card'>
    <div className='card-header'>NEW TOURNAMENT</div>
    <div className='card-body'>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <TextInput name='name' label='Name' />
          <TextInput name='location' label='Location' />
          <TextInput name='date' label='Date' type='date'/>
          <button type='submit' className='btn btn-primary' disabled={loading}>Save</button>
        </form>
      </FormProvider>
    </div>
  </div>
}