export const TYPES = {
  1: "normal",
  2: "fighting",
  3: "flying",
  4: "poison",
  5: "ground",
  6: "rock",
  7: "bug",
  8: "ghost",
  9: "steel",
  10: "fire",
  11: "water",
  12: "grass",
  13: "electric",
  14: "psychic",
  15: "ice",
  16: "dragon",
  17: "dark",
  18: "fairy",
}

export const findTypePerName = (name) => {
  for (let key in TYPES) {
    if (TYPES[key] === name) {
      return key;
    }
  }
  return null;
}
