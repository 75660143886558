import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { UserContext } from './auth'

export const usePlayers = (refresh) => {
  const [players, setPlayers] = useState([])
  const { token } = React.useContext(UserContext)

  useEffect(() => {
    axios.get("/backend/players", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      setPlayers(res.data)
    }
    ).catch(err => {
      console.log(err)
    })
  }, [refresh, token])

  return players
}