import { useState } from "react"
import { useTournaments } from "../../hooks/tournaments"
import { TournamentList } from "./List"
import { NewTournament } from "./New"

export const Tournaments = () => {

  const [refresh, setRefresh] = useState(new Date().toISOString())
  const tournaments = useTournaments(refresh)
  const tournamentId = useState(undefined)

  return <div className="row mt-2">
    <div className="col-12 col-xxl-8">
      <TournamentList tournaments={tournaments} callback={setRefresh} />
    </div>
    <div className="col-12 col-xxl-4">
      <NewTournament callback={setRefresh} />
    </div>
  </div>
}