import React, { useState, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { UserContext } from '../../hooks/auth';
import TextInput from '../../components/TextInput';
import axios from 'axios';
import { convertCountries } from '../../utils/countries';
import SelectInput from '../../components/SelectInput';

export const NewPlayer = ({ callback }) => {

  const methods = useForm()
  const { token } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const [countries, setCountries] = useState([])

  useEffect(() => {
    axios.get("https://restcountries.com/v3.1/all?fields=name,cca2").then(res => {
      setCountries(convertCountries(res.data).sort((a, b) => a.label.localeCompare(b.label)))
    })
  }, [])

  const handleSubmit = (data) => {
    if(loading) return;
    setLoading(true)
    axios.post("/backend/players", data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      setLoading(false)
      callback(new Date().toISOString())
    }).catch(err => {
      setLoading(false)
    })
  }

  return <div className='card mb-3'>
    <div className='card-header'>New Player</div>
    <div className='card-body'>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <TextInput name="firstname" label="Firstname" />
          <TextInput name="lastname" label="Lastname" />
          <TextInput name="nickname" label="Nickname" />
          <SelectInput name="country" label="Country" values={countries} />
          <button type="submit" className="btn btn-primary" disabled={loading}>Save</button>
        </form>
      </FormProvider>
    </div>
  </div>

}