import React, { useState } from 'react';
import { NewMatch } from './NewMatch';
import { ListMatches } from './List';
import { useParams } from 'react-router-dom';
import { EditMatch } from './Edit';

export const Matches = () => {
  
  const [showNew, setShowNew] = useState(false)
  const {matchId} = useParams()

  if(showNew) {
    return <div className='row mt-3'>
      <div className='col-12 text-end'>
        <button className='btn btn-secondary' onClick={() => setShowNew(false)}>Cancel</button>
      </div>
      <div className='col-12'>
        <NewMatch />
      </div>
    </div>
  }

  if(matchId) {
    return <div className='row mt-3'>
      <div className='col-12'>
        <EditMatch />
      </div>
    </div>
  }

  return <div className='row mt-3'>
    <div className='col-12 text-end'>
      <button className='btn btn-primary' onClick={() => setShowNew(true)}>New Match</button>
    </div>
    <div className='col-12 mt-3'>
    	<ListMatches />
    </div>
  </div> 
}