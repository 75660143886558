import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../../hooks/auth';
import { FormProvider } from 'react-hook-form';
import TextInput from '../../components/TextInput';
import SelectInput from '../../components/SelectInput';
import { useForm } from 'react-hook-form';
import { convertCountries } from '../../utils/countries';

export const EditPlayer = ({playerId, callback}) => {

  const [refresh, setRefresh] = useState(new Date().toISOString())
  const [player, setPlayer] = useState(undefined);

  const { token } = React.useContext(UserContext)

  const handleUpdate = () => {
    setRefresh(new Date().toISOString())
    setPlayer(undefined)
    callback(new Date().toISOString())
  }

  useEffect(() => {
    if(playerId) {
      axios.get(`/backend/players/${playerId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then(res => {
        setPlayer(res.data)
      }).catch(err => {
        console.log(err)
      })
    }
  }, [playerId, refresh])

  if(!player) {
    return <div className='alert alert-warning'><i className='fas fa-spinner fa-spin fa-fw me-2'></i>Loading Player Data...</div>
  }

  return <div className='card mb-3'>
    <div className='card-header'>{player.nickname}</div>
    <div className='card-body'>
      <CustomForm player={player} callback={handleUpdate} />
    </div>
  </div>
}

const CustomForm = ({player, callback}) => {
  const methods = useForm()
  const [countries, setCountries] = useState([])
  const [loading, setLoading] = useState(false)
  const { token } = React.useContext(UserContext)

  useEffect(() => {
    axios.get("https://restcountries.com/v3.1/all?fields=name,cca2").then(res => {
      setCountries(convertCountries(res.data).sort((a, b) => a.label.localeCompare(b.label)))
    })
  }, [])

  useEffect(() => {
    methods.reset(player)
  }, [player])

  const handleSubmit = (data) => {
    if(loading) return;
    setLoading(true)

    const { firstname, lastname, nickname, country } = data;

    axios.patch(`/backend/players/${player.id}`,  {
      firstname, lastname, nickname, country
    }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      callback()
    }).catch(err => {
      console.log(err)
    }).finally(() => {
      setLoading(false)
    })
  }

  return <FormProvider {...methods}>
    <form onSubmit={methods.handleSubmit(handleSubmit)}>
      <TextInput name="firstname" label="Firstname" />
      <TextInput name="lastname" label="Lastname" />
      <TextInput name="nickname" label="Nickname" />
      <SelectInput name="country" label="Country" values={countries} />
      <button type="submit" className="btn btn-primary" disabled={loading}>Update</button>
      <Link to='/players' className='btn btn-secondary ms-2'>Cancel</Link>
    </form>
  </FormProvider>
}