import { PokemonDataContext } from "../../hooks/pokemon"
import { useInterval } from "usehooks-ts";
import { useEffect, useState, useContext } from "react";
import { PokemonDataWrapper } from "../Ingame/ingame";
import axios from "axios";
import _ from "lodash";
import classNames from "classnames";
import "./Team.scss"
import { findTypePerName, TYPES } from "../../utils/types";

export const TeamPreviewOverlay = () => {

  useEffect(() => {
    //console.log("Mounting Ingame Overlay")

    let body = document.querySelector("body");
    body.classList.add("teampreview-overlay")

    return () => {
      body.classList.remove("teampreview-overlay")

      //console.log("Unmounting Ingame Overlay")
    }
  }, [])

  return <PokemonDataWrapper><TeamPreview /></PokemonDataWrapper>
}

const TeamPreview = ({ ...props }) => {

  const [match, setMatch] = useState(null)

  useInterval(() => {
    axios.get("/backend/pokemon/stream").then(response => {
      //console.log(response.data)
      setMatch(response.data)
    }).catch(error => {
      console.error(error)
    })
  }, 1000)


  if (match?.match === null || match?.team1 == null || match?.team2 == null) {
    console.log("Match not found")
    return;
  }

  let team1 = match.team1;
  let team2 = match.team2;
  let preview = match.match.config.team_preview || "team1";
  let team = undefined;

  if(preview == "team1") {
    team = team1;
  }
  if(preview == "team2") {
    team = team2;
  }

  if(!team) return;

  return <div className="team-preview">
    {_.times(6, (i) => {
      let pokemon = team["pokemon" + (i + 1)];
      return <PokemonPreview key={i} pokemon={pokemon} match={match} index={i} />
    })}
  </div>;
}

const PokemonPreview = ({pokemon, match, index}) => {
  const { items, pokemons, moves, abilities } = useContext(PokemonDataContext)
  let pokemonData = pokemons.find(a => a.name === pokemon.pokemon)
  let item = items.find(a => a.name === pokemon.item)
  let itemName = item.name;
  let itemEn = item.names.find(a => a.locale == "en")?.name
  let ability = abilities.find(a => a.name === pokemon.ability);
  let abilityEn = ability?.names.find(a => a.locale == "en")?.name
  let shiny = pokemon.shiny;


  let shinyPrefix = shiny ? "/shiny" : ""
  let imgPrefix = "http://localhost:3333"
  if (process.env.NODE_ENV === 'production') imgPrefix = "";

  let pokemonImage = `${imgPrefix}/pokemon_sprites/sprites/pokemon/other/official-artwork${shinyPrefix}/${pokemon?.id}.png`
  let itemImage = `${imgPrefix}/pokemon_sprites/sprites/items/dream-world/${itemName}.png`
  let teraImage = `${imgPrefix}/pokemon_sprites/sprites/types/generation-ix/scarlet-violet/TeraKristall/${pokemon?.tera.toLowerCase()}.png`

  console.log( index, ability, pokemon.ability)

  	return <div className={classNames("pokemon-preview", `pkm${index}`)}>
      <div className="pokemon-name">{pokemon.pokemon}</div>
      <div className="pokemon-body">
        <img src={pokemonImage} className="pokemon-sprite" />
        {pokemonData?.types.map((type, i) => {
          let t = findTypePerName(type)
          let img = `${imgPrefix}/pokemon_sprites/sprites/types/generation-ix/scarlet-violet/icon/${t}.png`
          return <div className={classNames("pokemon-type", `type${i + 1}`, type)}><img src={img} /><div>{type}</div></div>
        })}
        <div className="item-sprite"><img src={itemImage} /></div>
        <div className="tera-sprite"><img src={teraImage} /></div>
        <div className="pokemon-ability">{ability ? abilityEn : pokemon.ability.toUpperCase()}</div>
        <div className="pokemon-item">{itemEn}</div>
        <div className="pokemon-moves">
          {_.times(4, (i) => {
            let move = pokemon?.moves[i];
            let key = move.toLowerCase().replace(/ /g, "-")
            let m = moves.find(a => a.name === key)
            let n = m?.names.find(a => a.locale == "en")?.name
            let t = findTypePerName(m?.type)
            let img = `${imgPrefix}/pokemon_sprites/sprites/types/generation-ix/scarlet-violet/icon/${t}.png`
            return <div key={i} className="pokemon-move">
              <div className={classNames(m?.type, "type")}><img src={img} /></div>
              <div className="name">{n}</div>
            </div>
          })}
        </div>
      </div>
    </div>
}
