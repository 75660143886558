import { useFormContext, Controller } from "react-hook-form";
import classNames from "classnames";

const SelectInput = ({name, label, placeholder = "", required = true, readonly = false, values = []}) => {
  let htmlId = "text_input_" + name;
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={{required: required}}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: {invalid, error} }) => (
        <div className="form-floating mb-3">
          <select className={classNames("form-control", invalid ? "is-invalid" : "")} id={htmlId} value={value} readonly={readonly} disabled={readonly} onChange={onChange} ref={ref} >
          <option value="">Select {label}</option>
            {values.map((value, index) => <option key={index} value={value.value}>{value.label}</option>)}
          </select>
          <label htmlFor={htmlId} className="form-label">{label}</label>
          {invalid && <div className="invalid-feedback">{error.message || error.type}</div>}
        </div>
      )} 
    
    />
  )
}

// <input type={type} className={classNames("form-control", invalid ? "is-invalid" : "")} id={htmlId} placeholder={placeholder} value={value} readOnly={readonly} disabled={readonly} onChange={onChange} ref={ref} />

export default SelectInput;