import { useState } from "react";
import { useForm, FormProvider, useFormContext, Controller} from "react-hook-form"
import classNames from "classnames";
import axios from "axios";

export function LoginForm({token, setToken, ...props}) {

  const methods = useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (data) => {
    if(loading) return;
    setLoading(true);
    axios.post("/backend/auth/login", data).then(res => {
      console.log(res.data);

      if(res.data.errors) {
        Object.keys(res.data.errors).forEach((key) => {
          methods.setError(key, {
            type: "server",
            message: res.data.errors[key]
          })
        })
        return;
      }

      if(res.data.token){
        setToken(res.data.token);
        return;
      }

      throw new Error("Invalid response")
    }).catch((err) => {
      console.log(err)
      methods.setError("email", {
        type: "server",
        message: "Invalid email"
      })
    }).finally(() => {
      setLoading(false);
    })
  }

  return <div className="container-fluid h-100">
    <div className="row justify-content-center">
      <div className="col-12 col-md-10 col-lg-8 col-xl-6 col-xxl-4 mt-0 mt-md-4">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmit)}>
            <EmailField />
            <PasswordField />
            <button type="submit" className={"btn btn-primary " + (loading ? "disabled" : "")} disabled={loading}>Login</button>
          </form>
        </FormProvider>
      </div>
    </div>
  </div>
}

const EmailField = () => {
  const { control } = useFormContext()

  return <Controller
    name={"email"}
    control={control}
    rules={{required: true}}
    render={({ field: { onChange, onBlur, value, ref }, fieldState: {invalid, error} }) => (
      <div className="form-floating mb-3">
        <input type={"email"} className={classNames("form-control", invalid ? "is-invalid" : "")} id={"email"} placeholder={"E-Mail"} value={value} readOnly={false} disabled={false} onChange={onChange} ref={ref} />
        <label htmlFor={"email"}>E-Mail</label>
        {invalid && <div className="invalid-feedback">{error.message || error.type}</div>}
      </div>
    )} 
  />
}

const PasswordField = () => {
  const { control } = useFormContext()

  return <Controller
    name={"password"}
    control={control}
    rules={{required: true}}
    render={({ field: { onChange, onBlur, value, ref }, fieldState: {invalid, error} }) => (
      <div className="form-floating mb-3">
        <input type={"password"} className={classNames("form-control", invalid ? "is-invalid" : "")} id={"password"} placeholder={"Password"} value={value} readOnly={false} disabled={false} onChange={onChange} ref={ref} />
        <label htmlFor={"password"}>Password</label>
        {invalid && <div className="invalid-feedback">{error.message || error.type}</div>}
      </div>
    )} 
  />
}
