import React, { useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../hooks/auth';
import { Link } from 'react-router-dom';

export const PlayersList = ({ players, refresh }) => {
  
  if (players.length === 0) {
    return <div className="alert alert-warning"><i className="fas fa-spin fa-spinner fa-fw me-2"></i>Loading...</div>
  }
  
  return <div className='card mb-3'>
    <div className='card-header'>Tournaments</div>
    <div className='card-body p-1'>
      <table className='table table-dark table-bordered mb-0 table-striped'>
        <thead>
          <tr>
            <th>Nickname</th>
            <th>Firstname</th>
            <th>Lastname</th>
            <th>Country</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {players.map(player => <tr key={player.id}>
            <td>{player.nickname}</td>
            <td>{player.firstname}</td>
            <td>{player.lastname}</td>
            <td><img src={`https://flagcdn.com/h20/${player.country.toLowerCase()}.png`} /></td>
            <td className="text-end">
              <Link to={`/players/${player.id}/edit`} className='btn btn-success btn-sm me-2'>
                <i className='fas fa-edit'></i>
              </Link>
            </td>
          </tr>)}
        </tbody>
      </table>
    </div>
  </div>;

}

const DestoryButton = ({ id, callback }) => {

  const { token } = useContext(UserContext);

  const handleDestroy = () => {
    if (window.confirm('Are you sure?')) {
      axios.delete(`/backend/players/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(() => {
          console.log("Deleted player", id);
        })
        .catch(error => {
          console.log(error);
        }).finally(() => {
          callback(new Date().toISOString())
        })
    }
  }

  return <button className='btn btn-danger btn-sm' onClick={handleDestroy}>
    <i className='fas fa-trash-alt'></i>
  </button>;
}