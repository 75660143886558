import React, {useState} from "react";

export const UserContext = React.createContext({ 
  token: null,
  setToken: () => {},
  logout: () => {},
  nickname: null,
});

export function useJwtToken() {

  const getJwtToken = () => {
    return localStorage.getItem('broadcast_token');
  };

  const [token, setToken] = useState(getJwtToken());

  const saveJwtToken = token => {
    localStorage.setItem('broadcast_token', token);
    if(!token) {
      localStorage.removeItem("broadcast_token");
    }
    setToken(token);
  };

  return {
    setToken: saveJwtToken,
    token: token
  }
}