import React from "react";
import "./Menu.scss";
import { UserContext } from "../../hooks/auth";
import { NavLink } from "react-router-dom";

export const Menu = () => {

  const {nickname, logout} = React.useContext(UserContext);

  return <div className="d-flex flex-column flex-shrink-0 bg-dark header">
  <ul className="nav nav-pills flex-column mb-auto p-3">
    <li className="nav-item">
      <NavLink to="/" className="nav-link mb-1 text-white" aria-current="page">
        <i className="fas fa-home fa-fw me-2"></i>
        Home
      </NavLink>
    </li>
    <li>
      <NavLink to="/tournaments" className="nav-link mb-1 text-white">
        <i className="fas fa-trophy fa-fw me-2"></i>
        Tournaments
      </NavLink>
    </li>
    <li>
      <NavLink to="/players" className="nav-link text-white mb-1">
        <i className="fas fa-user fa-fw me-2"></i>
        Players
      </NavLink>
    </li>
    <li>
      <NavLink to="/teams" className="nav-link text-white mb-1">
        <i className="fas fa-users fa-fw me-2"></i>
        Teams
      </NavLink>
    </li>
    <li>
      <NavLink to="/matches" className="nav-link text-white mb-1">
        <i className="fas fa-list fa-fw me-2"></i>
        Matches
      </NavLink>
    </li>
    <li>
      <NavLink to="/stream" className="nav-link text-white mb-1">
        <i className="fas fa-signal-stream fa-fw me-2"></i>
        Stream
      </NavLink>
    </li>
  </ul>
  <div className="dropdown p-3 header-bottom">
    <span className="d-flex align-items-center text-white text-decoration-none justify-content-between">
      <span><i className="fas fa-user fa-fw me-2"></i>
      <strong>{nickname}</strong>
      </span>
      <span className="fas fa-sign-out fa-fw ms-2" onClick={
        () => {
          logout();
        }
      }></span>
    </span>
  </div>
</div>
}