import { useFormContext, Controller } from "react-hook-form";
import classNames from "classnames";

const TextInput = ({name, label, placeholder = "", required = true, type="text", readonly = false}) => {
  let htmlId = "text_input_" + name;
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={{required: required}}
      render={({ field: { onChange, onBlur, value, ref }, fieldState: {invalid, error} }) => (
        <div className="form-floating mb-3">
          <input type={type} className={classNames("form-control", invalid ? "is-invalid" : "")} id={htmlId} placeholder={placeholder} value={value} readOnly={readonly} disabled={readonly} onChange={onChange} ref={ref} />
          <label htmlFor={htmlId} className="form-label">{label}</label>
          {invalid && <div className="invalid-feedback">{error.message || error.type}</div>}
        </div>
      )} 
    
    />
  )
}

export default TextInput;