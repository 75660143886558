import React, { useState, useEffect } from "react"
import axios from "axios"
import { UserContext } from "./auth"

export const useTournaments = (refresh) => {
  const [tournaments, setTournaments] = useState([])
  const { token } = React.useContext(UserContext)

  useEffect(() => {
    axios.get("/backend/tournaments", {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }).then(res => {
      setTournaments(res.data)
    }).catch(err => {
      console.log(err)
    })
  }, [refresh, token])
  
  return tournaments
}