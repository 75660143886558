import axios from "axios";
import moment from "moment";
import { useContext } from "react";
import { UserContext } from "../../hooks/auth";

export const TournamentList = ({ tournaments, callback }) => {
  if (tournaments.length === 0) {
    return <div className="alert alert-warning"><i className="fas fa-spin fa-spinner fa-fw me-2"></i>Loading...</div>
  }

  return <div className='card mb-3'>
    <div className='card-header'>Tournaments</div>
    <div className='card-body p-1'>
      <table className='table table-dark table-bordered mb-0 table-striped'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Location</th>
            <th>Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {tournaments.map(tournament => <tr key={tournament.id}>
            <td>{tournament.name}</td>
            <td>{tournament.location}</td>
            <td>{moment(tournament.date).format("DD.MM.yyyy")}</td>
            <td className="text-end"></td>
          </tr>)}
        </tbody>
      </table>
    </div>
  </div>;
}

const DestoryButton = ({ id, callback }) => {

  const { token } = useContext(UserContext);

  const handleDestroy = () => {
    if (window.confirm('Are you sure?')) {
      axios.delete(`/backend/tournaments/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(() => {
          console.log("Deleted tournament", id);
        })
        .catch(error => {
          console.log(error);
        }).finally(() => {
          callback(new Date().toISOString())
        })
    }
  }

  return <button className='btn btn-danger btn-sm' onClick={handleDestroy}>
    <i className='fas fa-trash-alt'></i>
  </button>;
}